.plussa {
    position: relative;
    right: 15px;
    width: 100vw;
  }

  .sensd {

    position: absolute;
    top: -40px;
    right: 15px;
    color: #605446;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    font-size: 20px;
    text-align: center;
    border: none;
  }