/* @media(max-width:700px){ */

:root{

--color_text_slide:rgb(249, 252, 249);
}


.wrapper {
	/* background-color: rgba(248, 247, 245, 0.6); */

  background-color: #221b11  ;
   background-size: 100%;
	width: 100vw;
	height: 45px;
    padding-top: 10px;
  
    align-content: center;
    /* background-color: #948776; */
	
	/* overflow-x: scroll; */
    overflow-x: visible;
    overflow-y: hidden; 
	/* margin-left: 5vw; */
	display: grid;
	grid-template-columns: repeat(18, auto);
	grid-gap: 0 25px;
    /* position: fixed;
    
  
    top: 115px;
    z-index: 99; */
	margin: 0 auto;
    

}


/* .mard{
  
} */

.item {
   
    /* justify-content: center;
    align-content: center; */
    border-radius: 10px;
    padding-left: 3px;
    padding-right: 3px;
  
  
   
}


.item p{
   
    display: inline-block;
    width: max-content;
    height: 30px;
    font-size: 15pt;
    border-radius: 10px;
    /* color: var(--color_text_slide); */
    text-decoration: none;
    color: aliceblue;
}


.itemf {
	background-color: transparent;
   

	height: 35px;
    text-align: center;
    /* vertical-align: center; */
    padding-left: 3px;
    padding-right: 3px;
    border-radius: 10px;
    padding-bottom: 2px;
    padding-top: 2px;

}

.iteml {
    padding-top: 2px;
	background-color: transparent;
    
    padding-right: 3px;
    padding-right: 3px;
    padding-bottom: 2px;
    padding-top: 2px;
	
	height: 35px;
	
    text-align: center;
    /* vertical-align: center; */
    border-radius: 10px;

}

 
.itemf a{
    display: inline;
    text-align: center;
    font-size: 7pt;
    color: black;
    text-decoration: none;
}
.iteml a{
    display: inline;
    text-align: center;
    font-size: 7pt;
    color: black;
    text-decoration: none;
    z-index: 1;
}

/* .empty {
	width: 10px;
}

.h1ty {
	text-align: center;
}

.wrapper::-webkit-scrollbar {
  display: none; Chrome, Safari and Opera 
}

.wrapper {
  -ms-overflow-style: none;   IE and Edge 
  scrollbar-width: none;   Firefox 
  
}


 */
 