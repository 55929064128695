.cart-contin{

width: 100vw;
justify-content: center;
align-content: center;
background-color: antiquewhite;

}
.w-100{
    width: 100vw;
}

.txal-ce{
    text-align: center;
}

h6{
    color: rgb(29, 27, 27);
    text-align: center;
}
h5{
   font-size: 15pt;
   font-weight: 500;
    text-align: center;
    color: rgb(81, 20, 20);
}
.hbb{
    
    color: black;
}


.hcc{
    
    color: black;

}


/* <<<<<<<<<<<<< */

.cart-emp{
    /* background-color: rgb(73, 165, 98); */
    color: black;
}
.start-shopp{
    /* background-color: rgb(178, 111, 24); */
    color: black;
}

/* <<<<<<<<<<<<< */

.titl{
    /* background-color: rgb(101, 24, 178); */
    color: black;
    
}
.cabece{
    /* background-color: rgb(178, 24, 75); */
    color: black;
    
}

/* <<<<<<<<<<<<< */

.cart-it{

    /* background-color: rgb(31, 157, 31); */
    color: black;
}

.cart-prod{
    /* background-color: rgb(126, 103, 195); */
    color: black;
}

.cart-preci{
    color: black;

}


/* <<<<<<<<<<<<< */

.btns{

    border: none;
    outline: none;
    cursor: pointer;
    height: 35px;
    width: 35px;
    color: rgb(251, 249, 249);
    border-radius: 100%;
    font-size: 30px;
}


.btnsa{

    border: none;
    outline: none;
    cursor: pointer;
    height: 35px;
    width: 35px;
    color: rgb(251, 249, 249);
    border-radius: 100%;
    font-size: 14pt;
}


.delet{
    position: absolute;
   
    right: 10px;

}

.btns-dele{
    background-color: rgb(238, 17, 46);
   
   text-align: center;

}

.btns-plus{
    background-color:rgb(34, 109, 202); 
    position: relative;
    


}
.btns-less{
  
    background-color:rgb(34, 109, 202);
    text-align: center;
    /* vertical-align: text-top; */
    /* margin-bottom: 20px; */
    font-size: 30pt;
    position: relative;
   
}

.poss{
    display: block;
    position: absolute;
    top: -16px;
    right: 9px;
    text-align: center;

}
.possa{
    display: block;
    position: absolute;
    top: -7px;
    right: 6pt;
    text-align: center;
  
}
.possi{
    display: block;
    position: absolute;
    text-align: center;
    top: -6pt;
    right: 8px;

   
}


.btn-clear{
    background-color: rgb(143, 145, 157);
    border: none;
    outline: none;
    cursor: pointer;
    height: 45px;
    width: 120px;
    font-weight: 600;
    color: rgb(199, 26, 26);
    border-radius: 5px;
    font-size: 11pt !important;

}


.count{
    padding: 0.7rem 0;
    margin: 8px;
}
.bord-1{
    color: black;
    font-size: 16px;
    font-weight: 700;
    width: 35px;
    border: 0.5px solid rgb(177, 177, 177);

}


.alin{
    align-self: center;
    justify-self:center;
}
.alin-cont{
    align-content: center;
    justify-content:center;
}



.aling-para{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.displa{
    display: flex;
    flex-direction: column;
}

.roww{
    display: flex;
    flex-direction: row;
    
}

.spac-line{
    width: 80vw;
    margin-left: 7vw;
    padding-top: 1px;
    background-color: rgb(118, 123, 123);
    /* border: 1px solid rgb(177, 177, 177); */
}

.spac-line-modal{
    width: 70vw;
    margin-left: 11vw;
    padding-top: 1px;
    background-color: rgb(118, 123, 123);
   
}

.mas-space{
    width: 100%;
    padding-top: 30px;
}
/* <<<<<<<<<<<<< */
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[type='number']{
    width: 180px;
    height: 45px;
    font-size: 17pt;
    align-self: center;
    justify-self:center;
    margin-bottom: 10px;
    border: solid 2px black;
    border-radius: 5px;

}
input[type='text']{
    width: 80vw;
    border: solid 2px black;
    height: 45px;
    align-self: center;
    justify-self:center;
    margin-bottom: 10px;
    border-radius: 5px;

}
input[type='password']{
    width: 180px;
    align-self: center;
    justify-self:center;
    margin-bottom: 10px;

}
.btn-conect{
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 11pt;
    font-weight: 600;
    height: 45px;
    width: 180px;
    color: rgb(245, 239, 239);
   
    background-color: rgb(75, 121, 239);
    border-radius: 7px;

}

.btn-conect-close{
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 13pt;
    font-weight: 600;
    height: 35px;
    width: 180px;
    color: rgb(245, 239, 239);
    background-color: rgb(239, 157, 75);
    border-radius: 7px;
    
}


/* <<<<<<<<<<<<< */


.message{
    width: 80vw;
    border-radius: 40%;
}

/* <<<<<<<<<<<<< */

.m-b20s{
    margin-bottom: 20px;
}
.m-b10s{
    margin-bottom: 7px;
}

.m-t20s{
    margin-top: 20px;
}



