@media(max-width:900px){


body {

    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    overflow-x: hidden;
    margin: 0;
    background-color:#221b11 ;
    display: flex;
    flex-direction: column;
}

/* .log {
    background-color: #fefcf6;
} */

.mov{
    background-color:#221b11 ;

}
.logo_prin {
    width: 100vw;
    /* margin-left: 25vw; */
      display: flex;
      text-align: center;
      justify-content: space-around;
      padding-top: 30px;
      padding-bottom: 30px;
}



.mbdr {
    background-color:#221b11 ;
    margin: auto;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    position: relative;
    padding-bottom: 60px;
    text-align: center;
    justify-content: space-around;

  
}

.mbzz {
    margin: auto;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    padding-top: 5vh;
    text-align: center;
    justify-content: space-around;
    padding-bottom: 50px;
}


a {
    color: rgb(250, 250, 253);
    text-decoration: none;
    font-size: 16pt;
}


section {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
}


/* //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */


.foot {
position: relative;
bottom: 0;
padding-top: 60px;
}

.foot {
background-color: #221b11;
background-size: cover;

} 

.bac {
    z-index: -1;
    background-size: 100vw;
    background-repeat: no-repeat;
}


.colu{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    background-color: #221b11;
    background-size: 100vw;
}
.blanc{
    color: azure;
}

.logo {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #221b11;
    background-size: 100vh;
    padding-top: 30px;
    padding-bottom: 30px;
    opacity: 1;
}


.line{
    background-color: rgb(253, 253, 250);
    padding-top: 1px;
}

.bandera img:hover{
    transform: scale(1.3);
    transition: ease-in 0.7s;
    transition: ease-out 0.9s;
}

}