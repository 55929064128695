@media(max-width:900px){

.wi-100 {
    width: 100vw;
}

.wi-90{
    width: 90vw;
}

.wi-50{
    width: 50vw;
}

.lo img{
    width: 100vw;
}

.logo_prin img{
    width: 100vw;

}

.logos img{
    width: 90vw;

}

.menu img{
    width: 90px;

}

.gre img {
    width: 60px;
    height: 40px;
    border-radius: 40px;


}

.greek img {
    width: 60px;
    height: 40px;
    border-radius: 40px;
}

.logo img {
    width: 30px;
    z-index: 1;


}

.p-t-20{
    padding-top: 20px;
}

.pb-20{
    padding-bottom: 20px;
}

.tex-13{
    font-size: 14pt;
}

.tex-14{
    font-size: 14pt;
}

.tex-16{
    font-size: 16pt;
}

.tex-18{
    font-size: 18pt;
}

.tex-20{
    font-size: 20pt;
}

.tex-25{
    font-size: 25pt;
}

.tex-30{
    font-size: 30pt;
}

.tex-c{
color: aliceblue;
}
}
@media(min-width:900px){

    section{
        width: 40vw;
    }
    .mov1{
        width: 30vw;
    }
    .mov3{
        width: 30vw;
    }

    .wi-100 {
        width: 100%;
    }
    
    .wi-90{
        width: 90%;
       
       
       
    }
    .wi-50{
        width: 100%;
    }
    .logo_prin img{
        width: 100%;
    }

    .lo img{
        width: 100%;
       
       
    }
    
    .logos img{
        width: 100%;
       
       
    
    }
    
    .menu img{
        width: 60%;
       
       
    
    }

    
    .gre img {
        width: 60px;
        height: 40px;
        border-radius: 40px;
    }
    
    
    
    .greek img {
        width: 60px;
        height: 40px;
        border-radius: 40px;
    }
    
    
    
    .p-t-20{
        padding-top: 20px;
       
    }
    
    .pb-20{
        padding-bottom: 20px;
       
    }
    
    .tex-14{
        font-size: 18pt;
       
    }
    




a {
    color: rgb(250, 250, 253);
    text-decoration: none;
    font-size: 16pt;
}


.bac {
   
    z-index: -1;
   
    background-size: 100%;
    background-repeat: no-repeat;
   


}
.colu{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    background-color: #221b11;
    background-size: 100%;
}





.logo {
  
  
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #221b11;
    background-size: 100%;
    padding-top: 30px;
    padding-bottom: 30px;
    opacity: 1;

}


.line{
    background-color: rgb(250, 250, 247);
    padding-top: 1px;
}











/* //>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> */



}