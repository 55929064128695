
.link p{
color: white;
font-size: 16pt;
font-weight: 500;
text-align: center;

}

.active p{

color: rgb(107, 149, 212);
font-weight: 700;
font-size: 18pt;


}

  
  .nav {
    height: 35px;
    background-color: #9a9a9a;
    width: 100%;
    z-index: 99;
  }
  
  .sticky {
    position: fixed;
    top: 0;
  }
  
 
  
  
  
  